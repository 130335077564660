export const boxStyles = {
  flexGrow: 1,
};

export const toolbarStyles = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const textColorStyles = {
  color: 'white',
};
